<template>
    <div>
        <b-card class="donut-stats-card" v-if="data && donutTitle != 'اشتراك'">
            <b-card-title>
                <div
                    class="donut-stats-card__percent-text mt-1 d-flex"
                    style="justify-content: center; color: #d3d0d0;"
                >{{ calcuatePercent(series[0], series[1]) }}% من إجمالي {{ percentTitle }} الكلي</div>
            </b-card-title>
        </b-card>
        <div v-if="donutTitle != 'اشتراك'">
            <vue-apex-charts
                style="position:relative; bottom:52px"
                v-if="data.chartOptions"
                :height="height"
                :options="data.chartOptions"
                :series="series"
            />
        </div>
        <div v-if="donutTitle == 'اشتراك'">
            <vue-apex-charts
                style="position:relative; top:42px"
                v-if="data.chartOptions"
                :height="height"
                :options="data.chartOptions"
                :series="series"
            />
        </div>
    </div>
</template>

<script>
import {
    BCard
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";


export default {
    components: {
        BCard,
        VueApexCharts
    },
    props: {
        data: {
            chartOptions: {}
        },
        mainColor: String,
        series: Array,
        cardTitle: String,
        donutTitle: String,
        percentTitle: String,
        iconName: String,
        height: Number,
        labels: Array
    },
    methods: {
        calcuatePercent(first, second) {
            return ((first / (first + second)) * 100).toFixed(2)
        }
    },
}
</script>

<style lang="scss" scoped>
.donut-stats-card {
    &__header-container {
        display: flex;
        gap: 1.5rem;

        .donut-stats-card {
            &__header-container {
                display: flex;
                gap: 1.5rem;

                @media only screen and (max-width: 768px) {
                    justify-content: center;
                }
            }

            &__header {
                font-size: 1.4rem;
                color: #5e5873;
            }

            &__header-icon {
                background-color: rgba(234, 84, 85, 0.12);
            }

            &__percent-text {
                color: #b9b9c3;
                font-size: 1.1rem;
                font-weight: bold;
                line-height: 18px;

                @media only screen and (max-width: 768px) {
                    justify-content: center;
                }
            }
        }
    }
}
</style>