<template>
  <div>
    <b-card class="card" body-class="pb-50">
      <div style="display: block">
        <b-row>
          <b-col class="mb-2 style-sm">
            <div style="display: flex">
              <unicon class="icons1 flex-center" name="users-alt" fill="white"></unicon>
              <h6
                class="font-weight-bolder mb-1"
                style="display: grid; text-align: center"
              >
                {{ homeList.accountCount }}
                <span style="white-space: nowrap">عدد الحسابات</span>
              </h6>
            </div>
          </b-col>
          <b-col class="mb-2 style-sm">
            <div style="display: flex">
              <unicon
                class="icons2 flex-center"
                name="envelope-download"
                fill="white"
              ></unicon>
              <h6
                class="font-weight-bolder mb-1"
                style="display: grid; text-align: center"
              >
                {{ homeList.requsetesCount }}
                <span style="white-space: nowrap">عدد الطلبات</span>
              </h6>
            </div>
          </b-col>
          <b-col class="mb-2 style-sm">
            <div style="display: flex">
              <unicon class="icons3 flex-center" name="bell" fill="white"></unicon>
              <h6
                class="font-weight-bolder mb-1"
                style="display: grid; text-align: center"
              >
                {{ homeList.notificationCount }}
                <span style="white-space: nowrap">عدد الاشعارات</span>
              </h6>
            </div>
          </b-col>
          <b-col class="mb-2 style-sm">
            <div style="display: flex">
              <unicon class="icons4 flex-center" name="tv-retro" fill="white"></unicon>
              <h6
                class="font-weight-bolder mb-1"
                style="display: grid; text-align: center"
              >
                {{ homeList.servicesCount }}
                <span style="white-space: nowrap">عدد الاعلانات</span>
              </h6>
            </div>
          </b-col>
          <b-col class="mb-2 style-sm">
            <div style="display: flex">
              <unicon class="icons5 flex-center" name="bag" fill="white"></unicon>
              <h6
                class="font-weight-bolder mb-1"
                style="display: grid; text-align: center"
              >
                {{ homeList.jobCount }}
                <span style="white-space: nowrap">عدد الوظائف</span>
              </h6>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-row>
      <b-col lg="3">
        <b-card header-class="text-center" style="height: 300px">
          <template #header>
            <div class="d-flex">
              <unicon
                style="bottom: 4px"
                class="icon1 flex-center"
                height="19px"
                name="user-circle"
                fill="white"
              ></unicon>
              <h4>حسابات المستخدمين</h4>
            </div>
          </template>
          <ecommerce-earnings-chart
            style="position: relative; bottom: 25px"
            :data="branchDonutData"
            donutTitle="مستخدم"
            :series="[
              homeList.appUserCount,
              homeList.accountCount - homeList.appUserCount,
            ]"
            :height="120"
          />
        </b-card>
      </b-col>
      <b-col lg="3">
        <b-card style="height: 300px">
          <template #header>
            <div class="d-flex">
              <unicon
                style="bottom: 4px"
                class="icon2 flex-center"
                height="15px"
                name="store"
                fill="white"
              ></unicon>
              <h5>حسابات الفعاليات</h5>
            </div>
          </template>
          <ecommerce-earnings-chart
            style="position: relative; bottom: 25px"
            :data="branchDonut"
            donutTitle="فعالية"
            :series="[
              homeList.activityCount,
              homeList.accountCount - homeList.activityCount,
            ]"
            :height="120"
          />
        </b-card>
      </b-col>
      <b-col lg="6">
        <b-card style="height: 300px">
          <template #header>
            <div style="z-index: 22" class="d-flex">
              <unicon
                style="bottom: 4px"
                class="icon3 flex-center"
                height="15px"
                name="receipt"
                fill="white"
              ></unicon>
              <h5>الاشتراكات</h5>
            </div>
          </template>
          <div class="d-flex" style="justify-content: space-around">
            <div class="d-flex">
              <span class="dot1"></span>
              <p>فعاليات :{{ homeList.activitySubCount }}</p>
            </div>
            <div class="d-flex">
              <span class="dot2"></span>
              <P>مستخدمين :{{ homeList.appUserSubCount }}</P>
            </div>
          </div>
          <ecommerce-earnings-chart
            style="position: relative; bottom: 25px"
            :data="branchDonuts"
            donutTitle="اشتراك"
            :series="[homeList.activitySubCount, homeList.appUserSubCount]"
            :height="120"
          />
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-card>
          <chartjs-component-line-chart
            :height="400"
            :data="chartjsData.lineChart.data"
            :options="chartjsData.lineChart.options"
            :plugins="plugins"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import EcommerceEarningsChart from "./EcommerceEarningsChart.vue";
import ChartjsComponentLineChart from "./ChartjsComponentLineChart.vue";
import chartjsData from "./chartjsData";

export default {
  components: {
    EcommerceEarningsChart,
    ChartjsComponentLineChart,
  },
  data() {
    return {
      chartjsData,
      plugins: [
        {
          beforeInit(chart) {
            chart.legend.afterFit = function () {
              this.height += 20;
            };
          },
        },
      ],
    };
  },
  computed: {
    ...mapState({
      homeChart: (state) => state.home.homeChart,
      homeList: (state) => state.home.homeList,
      homeDto: (state) => state.home.homeDto,
      branchDonutData: (state) => state.home.branchDonutData,
      branchDonut: (state) => state.home.branchDonut,
      branchDonuts: (state) => state.home.branchDonuts,
    }),
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  created() {
    this.getHomeDash();
    this.getChartHome();
  },
  methods: {
    ...mapActions(["getHomeDash", "getChartHome"]),
  },
};
</script>
<style lang="scss" scoped>
.dot1 {
  height: 15px;
  width: 15px;
  background-color: #ffff00;
  border-radius: 50%;
  display: inline-block;
}
.dot2 {
  height: 15px;
  width: 15px;
  background-color: #55c662;
  border-radius: 50%;
  display: inline-block;
}
.style-sm {
  @media (min-width: 100px) and (max-width: 765px) {
    gap: 4px !important;
    display: flex;
    justify-content: center;
    padding-top: 8px;
    padding-bottom: 5px;
  }
}
.icons1 {
  padding: 10px;
  background-color: #a7d5f2;
  border-radius: 50%;
  position: relative;
  right: 10px;
  width: 45px;
  height: 45px;
}
.icon1 {
  background-color: rgb(184, 221, 245);
  border-radius: 50%;
  position: relative;
  right: 10px;
  bottom: 1px;
  width: 25px;
  height: 25px;
}
.icon2 {
  background-color: rgb(252, 159, 167);
  border-radius: 50%;
  position: relative;
  right: 10px;
  bottom: 1px;
  width: 25px;
  height: 25px;
}
.icon3 {
  background-color: rgb(251, 253, 123);
  border-radius: 50%;
  position: relative;
  right: 10px;
  bottom: 1px;
  width: 25px;
  height: 25px;
}
.icons2 {
  padding: 9px;
  background-color: rgb(175, 175, 248);
  border-radius: 50%;
  position: relative;
  right: 10px;
  width: 45px;
  height: 45px;
}
.icons3 {
  padding: 9px;
  background-color: rgb(252, 195, 195);
  border-radius: 50%;
  position: relative;
  right: 10px;
  width: 45px;
  height: 45px;
}
.icons4 {
  padding: 10px;
  background-color: rgb(191, 245, 191);
  border-radius: 50%;
  position: relative;
  right: 10px;
  width: 45px;
  height: 45px;
}
.icons5 {
  padding: 10px;
  background-color: rgb(252, 183, 218);
  border-radius: 50%;
  position: relative;
  right: 10px;
  width: 45px;
  height: 45px;
}
.nameUser {
  position: relative;
  left: 14px;
  top: 7px;
}
.apex-content {
  position: relative;
  top: 10px;
  right: 22px;
}
.apex-profit {
  position: relative;
  top: 27px;
  right: 59px;
}
</style>

<style lang="scss" scoped>
.driver-stats-card {
  &__item {
    position: relative;
    padding: 0.5rem;
  }

  &__donut-stats {
    width: 50px;
    height: 50px;
  }
}
</style>
